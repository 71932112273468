<template>
  <div class="douLinkPage">
    <div class="auPageCenter">
        <au-home-header title="快捷导航"></au-home-header>
        <div class="douLinkPageContent">
            <link-items :list="links"></link-items>
        </div>
    </div>
  </div>
</template>

<script>
import linkItems from "./linkItems.vue";
import douLinks from "../../utils/douLinks";
import auHomeHeader from './auHomeHeader.vue';
export default {
  data() {
    return {
      links: douLinks,
    };
  },
  components: { linkItems, auHomeHeader },
};
</script>

<style lang="scss" scoped>
.douLinkPageContent{
    padding: 45px 0;
}
</style>