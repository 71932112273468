<template>
  <div :class="('pageLinks' + sub)+' '+ (data.img?'ckt':'')+' '+ (data.link?'link':'')" v-for="(data, index) in list" :key="index" @click="toLink(data)">
    <img v-if="data.img" :src="data.img" />
    <div :class="'head' + sub">{{ data.title||data.text }}</div>
    <div v-if="data.data" :class="('content' + sub)+' '+ (data.data[0].img?'ckContent':'')">
      <link-items
        :list="data.data"
        :sub="sub + '-0'"
      ></link-items>
    </div>
  </div>
</template>

<script>
export default {
  name: "linkItems",
  props: {
    list: {
      default: [],
      type: Array,
    },
    sub: {
      default: "",
      type: String,
    },
  },
  methods:{
      toLink(data){
          if(data&&data.link){
              window.open(data.link)
          }
      }
  }
};
</script>

<style lang="scss" scoped>
.pageLinks{
    margin-bottom: 20px;
}
img {
  width: 24px;
  height: 24px;
  margin-right: 5px;
}
.head {
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 12px;
}
.head-0 {
  width: 66px;
  background: #ebeef5;
  height: 20px;
  text-align: center;
  line-height: 20px;
  border-radius: 3px;
  font-size: 12px;
  margin-right: 20px;
}
.content-0,
.pageLinks-0,
.ckContent {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}
.pageLinks-0 {
  margin-bottom: 10px;
}
.link:hover{
    color: var(--au-color);
}
.ckt{
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    min-width: 110px;
    cursor: pointer;
    width: 150px;
    text-align: left;
    line-height: 50px;
    margin-left: 62px;
}
</style>