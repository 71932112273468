<template>
  <div class="auBank2">
    <el-row align="middle" class="auPageCenter animate__animated animate__fadeInUp">
      <div class="items">
        <img src="../../../../assets/img/icon_computer.png" class="icon" />
        <div class="desc">
          <div class="title">极简使用</div>
          <div class="sub-title">践行简单易用、即安即用</div>
        </div>
      </div>
      <div class="items">
        <img src="../../../../assets/img/icon_wending.png" class="icon" />
        <div class="desc">
          <div class="title">稳定性强</div>
          <div class="sub-title">使用大规模、高规格的服务器</div>
        </div>
      </div>
      <div class="items">
        <img src="../../../../assets/img/icon_export.png" class="icon" />
        <div class="desc">
          <div class="title">数据导出</div>
          <div class="sub-title">主流电商平台数据实时导出</div>
        </div>
      </div>
      <div class="items">
        <img src="../../../../assets/img/icon_lock.png" class="icon" />
        <div class="desc">
          <div class="title">安全无忧</div>
          <div class="sub-title">非爬虫技术有效规避风险</div>
        </div>
      </div>
    </el-row>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.auBank2 {
  height: 55px;
}
.auPageCenter {
  height: 110px;
  display: flex;
  box-shadow: 0 0 27px 0 rgba(32, 32, 32, 0.14);
  border-radius: 4px;
  transition: all 0.5s;
  position: relative;
  top: -55px;
  background-color: #fff;
  box-sizing: border-box;
  .icon {
    width: 60px;
    height: 60px;
  }
  .items {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
  }
  .desc {
    margin-left: 16px;
  }
  .title {
    display: block;
    font-size: 14px;
    color: #3c4254;
  }
  .sub-title {
    display: block;
    margin-top: 6px;
    font-size: 14px;
    color: #9da0ad;
  }
}
</style>