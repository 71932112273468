<template>
  <el-card class="auCart">
    <template #header v-if="title">
      <div class="card-header">
        <span class="title">{{ title }}</span>
        <el-button class="button" type="text" v-if="!!buttonTitle" @click="$emit('click-button')">
          {{ buttonTitle }}
          <i class="el-icon-arrow-right"></i>
        </el-button>
      </div>
    </template>
    <slot></slot>
  </el-card>
</template>

<script>
export default {
  data(){return{
  }},
  props: {
    title: {
      type: String,
      default: "",
    },
    buttonTitle: {
      type: String,
      default: "",
    },
  }
};
</script>

<style lang="scss" scoped>
.card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.auCart {
  border: none;
  .title {
    font-size: 16px;
    font-weight: bold;
  }
  .button {
    font-size: 13px;
    float: right;
  }
  ::v-deep {
    .el-card__header {
      padding: 15px 20px;
    }
  }
}
</style>