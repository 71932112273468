
export default {
    // namespace:true,
    state: {
        scollType: "down",
        top: 0,
    },
    mutations: {
        setScollType(state:any, val:any) {
            state.scollType = val
        },
        setTop(state:any, val:any) {
            state.top = val;
        }
    },
    actions: {
        onScroll(context:any) {
            function onScroll() {
                const top = document.documentElement.scrollTop;
                const x=context.state.top-top;
                if(top>0 && (x>1 || x<-1)){
                    if (top < context.state.top) {
                        context.commit("setScollType", "top")
                    } else if (top > context.state.top) {
                        context.commit("setScollType", "down")
                    }
                }
                context.commit("setTop", top);
            }
            onScroll();
            window.addEventListener("scroll",onScroll);
        }
    },
    modules: {
    }
}
