<template>
  <router-view></router-view>
</template>

<script lang="ts">
import { useRouter } from 'vue-router'

export default {
  name: 'App',
  components: {
  },
  methods:{

  },
  setup(){
    const router=useRouter();


    return{
    }
  }
}
</script>

<style>

</style>
