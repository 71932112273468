<template>
  <div class="pageBank1">
    <div class="bg">
      <el-row class="auPageCenter" justify="space-between" align="middle">
        <div class="infos">
          <h3 class="animate__animated animate__fadeInLeft">
            土豆查 让数据会
            <span>说话</span>
          </h3>
          <p class="animate__animated animate__fadeInUp">产品矩阵已全面覆盖抖音、快手、微视，作者必备！</p>
          <a href="/extension" class="btn animate__animated animate__fadeInUp">
            免费试用
            <i class="el-icon-d-arrow-right"></i>
          </a>
        </div>
        <ul class="pic-box">
          <li class="layer">
            <img src="../../../../assets/img/circle_line.png" class="img1" />
          </li>
          <li class="layer">
            <img src="../../../../assets/img/computer_bg.png" class="img2" />
          </li>
          <li class="layer">
            <img src="../../../../assets/img/chart_bg.png" class="img3" />
          </li>
          <li class="layer">
            <img src="../../../../assets/img/popover_bg.png" class="img4" />
          </li>
        </ul>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.pic-box {
  position: relative;
  height: 370px;
  width: 584px;
  .layer {
    width: 100%;
    height: 100%;
    list-style: none;
  }
  .img1 {
    position: absolute;
    right: 0;
    top: 0;
    width: 381px;
    height: 378px;
  }
  .img2 {
    position: absolute;
    top: 48px;
    right: 30px;
    width: 460px;
    height: 369px;
  }
  .img3 {
    position: absolute;
    top: 14px;
    left: 14px;
    width: 239px;
    height: 196px;
  }
  .img4 {
    position: absolute;
    top: 96px;
    right: 37px;
    width: 92px;
    height: 37px;
  }
}
.infos {
  h3 {
    font-size: 46px;
    color: #fff;
    font-weight: 700;
    letter-spacing: 3px;
    text-shadow: 0 5px 8px rgba(47, 44, 168, 0.23);
    span {
      color: #27d9ff;
      position: relative;
      top: 2px;
      left: -8px;
    }
  }
  p {
    margin-top: 28px;
    font-size: 18px;
    color: #fff;
  }
  .btn {
    display: flex;
    margin-top: 50px;
    width: 142px;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    font-size: 16px;
    height: 46px;
    border-radius: 46px;
    color: #2c6cee;
    background-color: #fff;
    align-items: center;
    justify-content: center;
    i {
      margin-left: 6px;
      margin-top: 1px;
    }
  }
}
.auPageCenter {
  height: 100%;
}
.pageBank1 {
  width: 100%;
  min-width: 1200px;
  height: 600px;
  background: -o-linear-gradient(147deg, #2611c9, #7f95ff);
  background: linear-gradient(-57deg, #2611c9, #7f95ff);
  .bg {
    background: url("../../../../assets/bg_poster.png") no-repeat 0;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    // padding-top: 60px;
  }
}
</style>