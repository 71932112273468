
import { Options, Vue } from 'vue-class-component';
import AuBanner from './components/home/auBanner.vue';
import AuDouLinks from './components/home/auDouLinks.vue';
import AuModule from './components/home/auModule.vue';
import AuRank from './components/home/auRank.vue';
import auBank1 from './components/home/auBank1.vue';
import auBank2 from './components/home/auBank2.vue';
import auSwiper from './components/home/auSwiper.vue';

@Options({
  data(){return{

  }},
  components: {
    AuBanner,
    AuRank,
    AuModule,
    AuDouLinks,
    auBank1,
    auBank2,
    auSwiper,
  },
})
export default class Home extends Vue {}
