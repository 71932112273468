<template>
  <au-cart class="auRankCart" :title="title" button-title="更多">
    <table class="list">
      <thead>
        <tr>
          <th v-for="(it, index) of columns" :key="index" :class="{max:it.key=='title'}">
            {{ it.title }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, index) in list" :key="index">
          <td v-for="(col, colIndex) in columns" :key="colIndex">
            <el-row align="middle" :class="{max:col.key=='title'}">
                <el-image v-if="col.image" :src="_with(row,col.image)" class="img"></el-image>
                <span>{{ _with(row, col.key) }}</span>
            </el-row>
          </td>
        </tr>
      </tbody>
    </table>
  </au-cart>
</template>

<script>
import AuCart from "@/components/auCart.vue";

export default {
  components: { AuCart },
  data() {
    return {
    };
  },
  props: {
    title: {
      type: String,
      default: "卡片标题",
    },
    columns: {
      type: Array,
      default: [],
    },
    list: {
      type: Array,
      default: [],
    },
  },
};
</script>

<style lang="scss" scoped>
.auRankCart{
    transition: all .3s;
    box-shadow: 0 0 10px 0 rgba(0,0,0,.05);
    &:hover{
        box-shadow: 0 0 18px 0 rgba(0,0,0,.1);
    }
}
.list {
  height: 320px;
  width: 100%;
  text-align: left;
  .img{
    width: 42px;
    height: 42px;
    border-radius: 100%;
    margin-right: 12px;
  }
  th {
    color: #999;
  }
  td {
    color: #303133;
    span{
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-all;
      flex:1;
    }
  }
  .max{
    max-width: 150px;
    width: 150px;
  }
}
</style>