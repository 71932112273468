// import './plugins/axios'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import installElementPlus from './plugins/element'
import installAuUtils from './plugins/au'
import { Request } from '@/utils/request';
import VueAxios from 'vue-axios'
import "./style/global.scss"
import "animate.css"

const app = createApp(App)

//初始化element-plus
installElementPlus(app)
//初始化
installAuUtils(app)

app.use(VueAxios,Request.init())
.use(store)
.use(router)
.mount('#app')