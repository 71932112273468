<template>
  <div class="banner">
    <h3>{{ title }}</h3>
    <el-row class="homeBannerPage">
      <el-input
        class="auSearchInput"
        :class="{ fd: isFocus }"
        :placeholder="checkItem.placeholder"
        v-model="searchInput"
        @focus="isFocus = true"
        @blur="isFocus = false"
      >
        <template #append>
          <i class="icon el-icon-search"></i>
        </template>
      </el-input>
    </el-row>
    <el-row class="types">
      <div
        class="item"
        v-for="(it, index) in list"
        :class="{ check: it.id === checkId }"
        @click="onSelect(it)"
        :key="index"
      >
        {{ it.title }}
      </div>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isFocus: false,
      searchInput: "",
      checkId: "daren",
      list: [
        {
          id: "daren",
          title: "达人",
          placeholder: "请输入达人名称、抖音达人主页链接或抖音号",
        },
        {
          id: "shopItem",
          title: "商品",
          placeholder: "请输入商品链接、标题或者关键词",
        },
        {
          id: "live",
          title: "直播",
          placeholder: "请输入达人名称、抖音号或商品名称",
        },
        {
          id: "shop",
          title: "小店",
          placeholder: "请输入小店店铺标题",
        },
        {
          id: "video",
          title: "视频",
          placeholder: "请输入关键词或视频热词",
        },
      ],
    };
  },
  computed: {
    checkItem() {
      return this.list.filter((x) => x.id === this.checkId)[0];
    },
  },
  props: {
    title: {
      type: String,
      default: "土豆查，查数据，更方便，更简单",
    },
    subTitle: {
      type: String,
      default: "tudoucha.com",
    },
  },
  methods: {
    onSelect(it) {
      this.checkId = it.id;
    },
  },
};
</script>

<style lang="scss" scoped>
.auSearchInput {
  $sWidth: 700px;
  $sHeight: 60px;

  width: $sWidth;
  height: $sHeight;
  transform: scale(1);
  transition: all 0.3s;
  &.fd {
    transform: scale(1.1);
  }
  .icon {
    font-weight: bold;
    font-size: 22px;
    color: #fff;
  }
  ::v-deep {
    .el-input__inner {
      height: $sHeight;
      border: none;
    }
    .el-input-group__append {
      background-color: var(--au-color);
      border: none;
      cursor: pointer;
    }
  }
}
.banner {
  padding: 100px 0 60px;
  min-width: var(--au-width);
  background: url("../../../../assets/banner/banner1.png") center no-repeat;
  background-size: cover;
  color: #fff;
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  &>*{
    margin-bottom: 66px;
  }
  h3 {
    text-align: center;
    font-size: 52px;
    font-family: "宋体";
    letter-spacing: 10px;
  }
  .homeBannerPage{
    margin-bottom: 40px;
  }
  .types {
    &>*{
      margin-right: 18px;
      &:last-child{
        margin-right: 0;
      }
    }
  }
  .item {
    $h: 36px;
    font-size: 16px;
    height: $h;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    border-radius: $h;
    cursor: pointer;
    transition: all 0.3s;
    border: 1px solid rgba(145, 145, 145, 0);
    color: rgb(202, 202, 202);
    box-shadow: 0;
    margin-right: 18px;
    &:last-of-type{
      margin-right: 0;
    }
    &:hover {
      color: var(--au-color);
      border: 1px solid rgba(145, 145, 145, 0.9);
    }
    &.check {
      background: var(--au-color);
      border: 1px solid var(--au-color);
      color: #fff;
      box-shadow: 0 0 10px #290d94;
    }
  }
}
</style>