<template>
  <div class="auModu">
    <div class="auPageCenter">
      <au-home-header title="浏览器插件"></au-home-header>
      <el-carousel
        class="pageBannerHome"
        height="520px"
        :interval="4000"
        trigger="hover"
        arrow="never"
      >
        <el-carousel-item v-for="(item, index) in list" :key="index">
          <div class="pageBanner">
            <el-image class="img" fit="contain" :src="item.image"></el-image>
            <au-cart class="infos">
              <div class="flex">
                <div class="num">0{{ index + 1 }}</div>
                <h3>{{ item.title }}</h3>
                <p>{{ item.text }}</p>
                <el-button class="btn" @click="toDownloadPage"
                  >立即下载</el-button
                >
              </div>
            </au-cart>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
import AuCart from "@/components/auCart.vue";
import auHomeHeader from "./auHomeHeader.vue";

export default {
  components: { auHomeHeader, AuCart },
  data() {
    return {
      list: [
        {
          image: require("@/assets/screenshot/ig1.png"),
          title: "嵌入式插件",
          text: "数据查看、视频、音频下载",
        },
        {
          image: require("@/assets/screenshot/ig2.png"),
          title: "快捷下载",
          text: "一键导出表格、下载无水印视频",
        },
        {
          image: require("@/assets/screenshot/ig3.png"),
          title: "评论采集",
          text: "采集评论、精准定位用户群体",
        },
        {
          image: require("@/assets/screenshot/ig4.png"),
          title: "批量操作",
          text: "批量下载视频、导出用户信息",
        },
      ],
    };
  },
  props: {},
  methods: {
    toDownloadPage() {
      this.$router.push("/extension");
    },
  },
};
</script>

<style lang="scss" scoped>
$h: 240px;
$w: 320px;
$w2: $w * 0.4;
$p:45px;
.auModu{
  // background: linear-gradient(0deg,#1c2040,#3a4362);
  padding: 0 0 $p;
}
.pageBannerHome {
  margin: $p 0;
}
.pageBanner {
  position: relative;
  background: #fff;
  .infos {
    position: absolute;
    left: 8px;
    top: 50%;
    width: $w;
    height: $h;
    margin-top: $h / 2 * -1;
    background: rgba(255, 255, 255, 1);
  }
  .flex {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    height: $h - 40px;
    & > * {
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .num {
    font-size: 124px;
    position: absolute;
    top: -19px;
    left: 31px;
    color: #ddd;
    z-index: 1;
  }
  h3 {
    font-size: 24px;
  }
  p {
    font-size: 16px;
  }
  h3,
  .btn,
  p {
    position: relative;
    z-index: 1;
    top: 30px;
  }
  .img {
    width: calc(100% - 0);
    height: 520px;
    position: relative;
    left: $w2;
  }
  &.right {
    .infos {
      left: auto;
      right: 8px;
    }
    .img {
      left: auto;
      // right: $w2;
    }
  }
}
.auPageCenter {
  // height: 620px;
  ::v-deep {
    .el-carousel__button {
      background: var(--au-color);
    }
  }
}
</style>