<template>
  <div class="auPageCenter">
    <au-home-header title="抖音热门榜单"></au-home-header>
    <div class="sub">数据更新日期：{{toDay}}</div>
    <!-- 列表-->
    <el-row class="rankList" :gutter="18">
      <el-col :span="8">
        <au-rank-cart :columns="live_hot" :list="temp_live_hot" title="热门直播间"></au-rank-cart>
      </el-col>
      <el-col :span="8">
        <au-rank-cart :columns="shop_hot" :list="temp_shop_hot" title="热门商品榜"></au-rank-cart>
      </el-col>
      <el-col :span="8">
        <au-rank-cart :columns="user_hot" :list="temp_user_hot" title="热门达人榜"></au-rank-cart>
      </el-col>
    </el-row>
    <el-row class="rankList" :gutter="18">
      <el-col :span="8">
        <au-rank-cart :columns="video_hot" :list="temp_video_hot" title="热门视频"></au-rank-cart>
      </el-col>
      <el-col :span="8">
        <au-rank-cart :columns="music_hot" :list="temp_music_hot" title="热门音乐"></au-rank-cart>
      </el-col>
      <el-col :span="8">
        <au-rank-cart :columns="huati_hot" :list="temp_huati_hot" title="热门话题"></au-rank-cart>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import auHomeHeader from "./auHomeHeader.vue";
import AuRankCart from './auRankCart.vue';
import {shop_auther,shop_hot,user_hot,
test_list,temp_shop_hot,temp_user_hot,
live_hot,temp_live_hot,
video_hot,temp_video_hot,
huati_hot,temp_huati_hot,
music_hot,temp_music_hot} from '../../utils/homeListColumns'

const dateFormat = require("date-format");
export default {
  components: { auHomeHeader, AuRankCart },
  data() {
    return {};
  },
  computed: {
      shop_auther:()=>shop_auther,
      shop_hot:()=>shop_hot,
      user_hot:()=>user_hot,
      live_hot:()=>live_hot,
      video_hot:()=>video_hot,
      music_hot:()=>music_hot,
      huati_hot:()=>huati_hot,
      //测试数据
      test_list:()=>test_list,
      temp_shop_hot:()=>temp_shop_hot,
      temp_user_hot:()=>temp_user_hot,
      temp_live_hot:()=>temp_live_hot,
      temp_video_hot:()=>temp_video_hot,
      temp_music_hot:()=>temp_music_hot,
      temp_huati_hot:()=>temp_huati_hot,
    toDay() {
      return dateFormat("yyyy-MM-dd", new Date());
    },
  },
};
</script>

<style lang="scss" scoped>
.auPageCenter{
    padding: 15px 0 45px;
}
.rankList{
    padding: 0 18px 30px;
}
.sub {
  font-size: 16px;
  color: #666666;
  text-align: center;
  margin-bottom: 32px;
}
</style>