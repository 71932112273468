
//带货主播榜
export const shop_auther = [
    {
        title: "排名",
        key: "ranking",
    },
    {
        title: "播主",
        key: "title",
        image: "image",
    },
    {
        title: "销量",
        key: "sales",
    },
    {
        title: "销售额",
        key: "salesVal",
    },
]

//热门商品榜
export const shop_hot = [
    {
        title: "排名",
        key: "ranking",
    },
    {
        title: "商品",
        key: "title",
        image: "image",
    },
    {
        title: "销量",
        key: "sales",
    },
    {
        title: "销售额",
        key: "salesVal",
    },
]

//热门达人榜
export const user_hot = [
    {
        title: "排名",
        key: "ranking",
    },
    {
        title: "博主",
        key: "title",
        image: "image",
    },
    {
        title: "粉丝",
        key: "sales",
    },
    {
        title: "粉丝增量",
        key: "salesVal",
    },
]

//热门直播间
export const live_hot = [
  {
      title: "排名",
      key: "ranking",
  },
  {
      title: "播主",
      key: "title",
      image: "image",
  },
  {
      title: "人气",
      key: "sales",
  },
  {
      title: "音浪",
      key: "salesVal",
  },
]

//热门视频
export const video_hot = [
  {
      title: "排名",
      key: "ranking",
  },
  {
      title: "视频",
      key: "title",
      image: "image",
  },
  {
      title: "点赞",
      key: "sales",
  },
  {
      title: "评论",
      key: "salesVal",
  },
]

//热门音乐
export const music_hot = [
  {
      title: "排名",
      key: "ranking",
  },
  {
      title: "音乐",
      key: "title",
      image: "image",
  },
  {
      title: "使用人数",
      key: "sales",
  },
  {
      title: "增量",
      key: "salesVal",
  },
]
//热门话题
export const huati_hot = [
  {
      title: "排名",
      key: "ranking",
  },
  {
      title: "话题",
      key: "title",
  },
  {
      title: "使用增量",
      key: "sales",
  },
  {
      title: "播放增量",
      key: "salesVal",
  },
]



//-------------------

export const temp_huati_hot=[
  {
    title: "记录美好童年",
    ranking: "1",
    sales: "12.9w",
    salesVal: "6,757w",
  },
  {
    title: "上热门",
    ranking: "2",
    sales: "10.8w",
    salesVal: "6.99亿",
  },
  {
    title: "兄弟姐妹们晚上好",
    ranking: "3",
    sales: "8.2w",
    salesVal: "5,102.3w",
  },
  {
    title: "我的小公主",
    ranking: "4",
    sales: "6.8w",
    salesVal: "5,259.3w",
  },
  {
    title: "电台",
    ranking: "5",
    sales: "6.2w",
    salesVal: "14.99亿",
  },
]

//热门用域-数据
export const temp_music_hot=[
  {
    title: "@杨嘉豪创作的原声",
    image:require("@/assets/music.png"),
    ranking: "1",
    sales: "92.6w",
    salesVal: "20.6w",
  },
  {
    title: "@幸福创作的原声",
    image:require("@/assets/music.png"),
    ranking: "2",
    sales: "41.1w",
    salesVal: "19.1w",
  },
  {
    title: "@樊静儿创作的原声",
    image:require("@/assets/music.png"),
    ranking: "3",
    sales: "71.8w",
    salesVal: "17.2w",
  },
  {
    title: "青花瓷",
    image:require("@/assets/music.png"),
    ranking: "4",
    sales: "52.2w",
    salesVal: "12.5w",
  },
  {
    title: "@伤感强强创作的原声",
    image:require("@/assets/music.png"),
    ranking: "5",
    sales: "97.5w",
    salesVal: "9.6w",
  },
]

//热门视频-数据
export const temp_video_hot=[
  {
    title: "好拽 一下子就抓住了我的心❤️",
    image:
      "https://cdn-images.chanmama.com/douyin/custom/a14b143960681d09d56b05617dde442b.jpeg?source=https%3A%2F%2Fp26.douyinpic.com%2Ftos-cn-p-0015%2Fc923bf9bdace4f62b7fec16166e1d873_1629863210~c5_200x200.jpeg%3Ffrom%3D4257465056%26s%3D%26se%3Dfalse%26sh%3D%26sc%3D%26l%3D202108260133070101501541020D01D2A7%26biz_tag%3Dfeed_cover",
    ranking: "1",
    sales: "16.9w",
    salesVal: "2.6w",
  },
  {
    title: "#社交牛逼症 #社恐",
    image:
      "https://cdn-images.chanmama.com/douyin/custom/d76115106820b625be27ad219b7423e7.jpeg?source=https%3A%2F%2Fp6.douyinpic.com%2Ftos-cn-p-0015%2F27acab32f3764c7caef59f4d736da576_1629882107~c5_200x200.jpeg%3Ffrom%3D4257465056%26s%3D%26se%3Dfalse%26sh%3D%26sc%3D%26l%3D202108260133150101501660305D06A95A%26biz_tag%3Dfeed_cover",
    ranking: "2",
    sales: "28.3w",
    salesVal: "4.3w",
  },
  {
    title: "传奇走了。2021年8月25号晚上10点14分。",
    image:
      "https://cdn-images.chanmama.com/douyin/custom/f3db6129692556ce2e3a42f540c7cc83.jpeg?source=https%3A%2F%2Fp11.douyinpic.com%2Ftos-cn-p-0015%2F742bfbc457064159a82d0496ef72541d_1629901029~c5_200x200.jpeg%3Ffrom%3D4257465056%26s%3D%26se%3Dfalse%26sh%3D%26sc%3D%26l%3D202108260133260101501560820506691A%26biz_tag%3Dfeed_cover",
    ranking: "3",
    sales: "50.4w",
    salesVal: "4.8w",
  },
  {
    title: "现在与过去没法比！#上热门@抖音小助手",
    image:
      "https://cdn-images.chanmama.com/douyin/custom/d33db658e92d582d67cf1b4acdeea86f.jpeg?source=https%3A%2F%2Fp26.douyinpic.com%2Ftos-cn-p-0015%2F274f58c7b89945369527cf07342cd6e4_1629863834~c5_200x200.jpeg%3Ffrom%3D4257465056%26s%3D%26se%3Dfalse%26sh%3D%26sc%3D%26l%3D2021082601330801015118610129034D52%26biz_tag%3Dfeed_cover",
    ranking: "4",
    sales: "19.5w",
    salesVal: "7,616",
  },
  {
    title: "这必须有我老公的一席之地 #社交牛逼症合集 #行为艺术",
    image:
      "https://cdn-images.chanmama.com/douyin/custom/37791e7ca39c894d87460e974aaac2f5.jpeg?source=https%3A%2F%2Fp11.douyinpic.com%2Ftos-cn-p-0015%2F596eb0961cd64699a2c5bff74afaec4f_1629856220~c5_200x200.jpeg%3Ffrom%3D4257465056%26s%3D%26se%3Dfalse%26sh%3D%26sc%3D%26l%3D202108260133040101511982003807A042%26biz_tag%3Dfeed_cover",
    ranking: "5",
    sales: "18w",
    salesVal: "2.9w",
  },
]
//热门商品-数据
export const temp_shop_hot=[
  {
    title: "旗袍4",
    image:
      "https://cdn-images.chanmama.com/douyin/product/f87286326c30af6d14e2581d6462e2e7.jpeg?source=https%3A%2F%2Fp1.pstatp.com%2Fthumb%2Ftemai%2F6482be8dc148965ecf2a0c8e02c9c766www615-615",
    ranking: "1",
    sales: "20.1w",
    salesVal: "20.1w",
  },
  {
    title: "白玉传国玉玺.BTZB@@bj0261（窦总专享8.24）",
    image:
      "https://cdn-images.chanmama.com/douyin/product/964b8c9e668b7166effed1a659b8d968.jpeg?source=https%3A%2F%2Fp9.pstatp.com%2Fthumb%2Ftemai%2F40767e58e5055818ed3e66ad79c0686dwww1080-1440",
    ranking: "2",
    sales: "12w",
    salesVal: "13.9w",
  },
  {
    title: "【国岳粉丝推荐】小苏打超浓缩香水洗衣液6袋500g装",
    image:
      "https://cdn-images.chanmama.com/douyin/product/0aeb48cd96ec1bd67ad40e26cedd5d42.jpeg?source=https%3A%2F%2Fp3.pstatp.com%2Fthumb%2Ftemai%2Fe73a14658b1c1dc8416f16fef7211220www800-800",
    ranking: "3",
    sales: "10.9w",
    salesVal: "68.8w",
  },
  {
    title: "白玉观音摆件.BTZB@@gj0263(窦总专享8.24）",
    image:"https://cdn-images.chanmama.com/douyin/product/a655cbf6b553083bac09dec825f3625f.jpeg?source=https%3A%2F%2Fp3.pstatp.com%2Fthumb%2Ftemai%2F97ba57e9dad4ecf3adc6212521a6b57dwww1080-1440",
    ranking: "4",
    sales: "7w",
    salesVal: "16.4w",
  },
  {
    title: "【果江南】香甜徐香猕猴桃4.5-5斤坏果包赔陕西汉中新鲜水果",
    image:"https://cdn-images.chanmama.com/douyin/product/b4c75c3ec511267a8616e49943b903a8.jpeg?source=https%3A%2F%2Fp1.pstatp.com%2Fthumb%2Ftemai%2Fa6a0e8befde1ace21616707767bf2f9dwww800-800",
    ranking: "5",
    sales: "6.8w",
    salesVal: "54亿",
  },
]

//热门达人-数据
export const temp_user_hot=[
  {
    title: "龙龙瑜哥哥",
    image:
      "https://cdn-images.chanmama.com/douyin/avatar/c198954191c89e90eb9b63c468762b14.jpeg?source=https%3A%2F%2Fp29.douyinpic.com%2Fimg%2Ftos-cn-avt-0015%2F3631d4d59af6bcf4363f05df74313f22~c5_200x200.jpeg%3Ffrom%3D2956013662",
    ranking: "1",
    sales: "75.5w",
    salesVal: "64.3w",
  },
  {
    title: "月老",
    image:
      "https://cdn-images.chanmama.com/douyin/avatar/c9754a1f68ec37dfb4ca7bb1b6de0ad1.jpeg?source=https%3A%2F%2Fp26.douyinpic.com%2Fimg%2Ftos-cn-avt-0015%2Fdc1ea2abaa04cf0dd2862f6912c9b9f8~c5_200x200.jpeg%3Ffrom%3D2956013662",
    ranking: "2",
    sales: "575w",
    salesVal: "47.7w",
  },
  {
    title: "故事天下",
    image:
      "https://cdn-images.chanmama.com/douyin/avatar/9e00abca640ad7dda60c54597367d993.jpeg?source=https%3A%2F%2Fp3.douyinpic.com%2Fimg%2Ftos-cn-i-0813%2Fb7600cc6e6ee4ca2a75bbe11e8afec83~c5_200x200.jpeg%3Ffrom%3D2956013662",
    ranking: "3",
    sales: "99.1w",
    salesVal: "38.1w",
  },
  {
    title: "李宗伊",
    image:
      "https://cdn-images.chanmama.com/douyin/avatar/4d6be8ef376e07a58c3456ee62ba45b2.jpeg?source=https%3A%2F%2Fp26.douyinpic.com%2Fimg%2Ftos-cn-avt-0015%2Fd3d60385c314d7399fdd81f39931bc59~c5_200x200.jpeg%3Ffrom%3D2956013662",
    ranking: "4",
    sales: "230.6w",
    salesVal: "32w",
  },
  {
    title: "小甜酒的花裙子",
    image:
      "https://cdn-images.chanmama.com/douyin/avatar/47510b4035c9ccc0b3573dc8d2b04451.jpeg?source=https%3A%2F%2Fp3.douyinpic.com%2Fimg%2Ftos-cn-avt-0015%2F2707e0288df558ffe8c5fca929bdd715~c5_200x200.jpeg%3Ffrom%3D2956013662",
    ranking: "5",
    sales: "94.8w",
    salesVal: "31.8w",
  },
]

//直播间-数据
export const temp_live_hot=[
  {
    title: "抖音第一网红直播中",
    image:
      "https://cdn-images.chanmama.com/douyin/custom/d22a1e38faa6c6094771216a7ce0a58c.jpeg?source=https%3A%2F%2Fp6-webcast.douyinpic.com%2Fimg%2Fwebcast%2F6927171342492207875~tplv-resize%3A0%3A0.image",
    ranking: "1",
    sales: "78.8w",
    salesVal: "1,423.1w",
  },
  {
    title: "舞帝利哥直播了",
    image:
      "https://cdn-images.chanmama.com/douyin/custom/6ebd990cb869560fefbe015743288d87.jpeg?source=https%3A%2F%2Fp6-webcast.douyinpic.com%2Fimg%2Fwebcast%2F6992112341761067809~tplv-resize%3A0%3A0.image",
    ranking: "2",
    sales: "57.5w",
    salesVal: "925.8w",
  },
  {
    title: "《新闻联播》正在直播",
    image:
      "https://cdn-images.chanmama.com/douyin/custom/03774154055837e2b871587dee22026a.jpeg?source=https%3A%2F%2Fp9-webcast.douyinpic.com%2Fimg%2Fwebcast%2F6d8dc6a9a0b87b3031695321fe99ba30.jpeg~tplv-resize%3A0%3A0.image",
    ranking: "3",
    sales: "46w",
    salesVal: "0",
  },
  {
    title: "-",
    image:
      "https://cdn-images.chanmama.com/douyin/custom/7e81363cfcc60ec1812c28da12e1988c.jpeg?source=https%3A%2F%2Fp9-webcast.douyinpic.com%2Fimg%2Fwebcast%2F6972493438201727784~tplv-resize%3A0%3A0.image",
    ranking: "4",
    sales: "39.4w",
    salesVal: "31.9w",
  },
  {
    title: "贵人鸟 无人可挡！",
    image:
      "https://cdn-images.chanmama.com/douyin/custom/3bfcba2fddcc216492eda0aaa995f52c.jpeg?source=https%3A%2F%2Fp6-webcast.douyinpic.com%2Fimg%2Fwebcast%2F7000255785150384935~tplv-resize%3A0%3A0.image",
    ranking: "5",
    sales: "39.4w",
    salesVal: "0",
  },
]

export const test_list=[
    {
      title: "罗永浩",
      image:
        "2956013662",
      ranking: "1",
      sales: "11",
      salesVal: "22",
    },
    {
      title: "罗永浩",
      image:
        "2956013662",
      ranking: "2",
      sales: "11",
      salesVal: "22",
    },
    {
      title: "罗永浩",
      image:
        "2956013662",
      ranking: "3",
      sales: "11",
      salesVal: "22",
    },
    {
      title: "罗永浩",
      image:
        "2956013662",
      ranking: "4",
      sales: "11",
      salesVal: "22",
    },
    {
      title: "罗永浩",
      image:
        "2956013662",
      ranking: "5",
      sales: "11",
      salesVal: "22",
    },
]






