<template>
  <div class="home">
    <!-- banner -->
    <!-- <au-banner></au-banner> -->
    <!-- 第一块介绍 -->
    <au-bank-1></au-bank-1>
    <au-bank-2></au-bank-2>
    <!-- 榜单数据 -->
    <au-rank class="animate__animated animate__fadeInUp"></au-rank>
    <!-- 图片 -->
    <!-- <au-module></au-module> -->
    <au-swiper class="animate__animated animate__fadeInUp"></au-swiper>
    <!-- 链接数据 -->
    <au-dou-links class="animate__animated animate__fadeInUp"></au-dou-links>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import AuBanner from './components/home/auBanner.vue';
import AuDouLinks from './components/home/auDouLinks.vue';
import AuModule from './components/home/auModule.vue';
import AuRank from './components/home/auRank.vue';
import auBank1 from './components/home/auBank1.vue';
import auBank2 from './components/home/auBank2.vue';
import auSwiper from './components/home/auSwiper.vue';

@Options({
  data(){return{

  }},
  components: {
    AuBanner,
    AuRank,
    AuModule,
    AuDouLinks,
    auBank1,
    auBank2,
    auSwiper,
  },
})
export default class Home extends Vue {}
</script>

<style lang="scss" scoped>
.home{
  margin-top: -60px;
}
</style>

