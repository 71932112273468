<template>
  <div class="pageSwiper">
    <h3>插件功能说明</h3>
    <!-- 切换 -->
    <div class="swiperPage">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item, index) in list" :key="index">
            <div class="swiperSlidePage">
              <!-- <el-image class="img" fit="cover" :src="item.image"></el-image> -->
              <img :src="item.image" class="img" />
              <au-cart class="infos">
                <div class="flex">
                  <div class="num">0{{ index + 1 }}</div>
                  <h2>{{ item.title }}</h2>
                  <p>{{ item.text }}</p>
                  <a href="/extension" class="btn">立即使用</a>
                </div>
              </au-cart>
            </div>
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
      <div class="auSwiperPrev" @click="mySwiper.slidePrev()">
        <img src="@/assets/img/arrow_left_white.png" alt="" />
      </div>
      <div class="auSwiperNext" @click="mySwiper.slideNext()">
        <img src="@/assets/img/arrow_left_white.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "@/assets/libs/swiper-bundle.min.js";
import "@/assets/libs/swiper-bundle.min.css";

export default {
  components: {},
  data() {
    return {
      mySwiper: null,
      list: [
        {
          image: require("@/assets/screenshot/ig1.png"),
          title: "嵌入式插件",
          text: "数据查看、视频、音频下载",
        },
        {
          image: require("@/assets/screenshot/ig2.png"),
          title: "快捷下载",
          text: "一键导出表格、下载无水印视频",
        },
        {
          image: require("@/assets/screenshot/ig3.png"),
          title: "评论采集",
          text: "采集评论、精准定位用户群体",
        },
        {
          image: require("@/assets/screenshot/ig4.png"),
          title: "批量操作",
          text: "批量下载视频、导出用户信息",
        },
      ],
    };
  },
  methods: {
    loadSwiper() {
      this.mySwiper = new Swiper(".swiper-container", {
        direction: "horizontal",
        loop: true,
        shadows: true,
        // width: 914,
        // 如果需要分页器
        pagination: {
          el: ".swiper-pagination",
        },
      });
    },
  },
  mounted() {
    this.loadSwiper();
  },
};
</script>

<style lang="scss" scoped>
h3 {
  font-size: 32px;
  color: #fff;
  font-weight: 400;
  text-align: center;
  margin-bottom: 80px;
}
.pageSwiper {
  background: linear-gradient(0deg, #1c2040, #3a4362);
  padding-top: 64px;
  padding-bottom: 94px;
  color: #fff;
}
$sh: 420px;
$sw: 920px;
.swiperSlidePage {
  width: $sw;
  margin: auto;
  display: flex;
  .img {
    width: $sw - 290px;
    height: $sh;
    object-fit: cover;
  }
  .infos {
    width: 290px;
    height: $sh;
    box-sizing: border-box;
    background: #fff;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding: 34px;
    background-color: #fff;
  }
  .flex {
    display: flex;
    flex-flow: column;
    justify-content: center;
    height: 100%;
    align-items: baseline;
  }
  h2 {
    font-size: 22px;
    color: #3c4254;
    margin-bottom: 30px;
  }
  p {
    font-size: 14px;
    color: #9da0ad;
    line-height: 24px;
    margin-bottom: 30px;
  }
  .btn {
    position: relative;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    padding: 0 30px;
    border-radius: 2px;
    background-color: #f6f8fb;
    border: 1px solid #f6f8fb;
    color: #2c6cee;
    border-radius: 100px;
    cursor: pointer;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    height: 34px;
    font-size: 12px;
    background-color: #2c6cee;
    color: #fff;
    border-color: #2c6cee;
    &:hover {
      background-color: #145cec;
      border-color: #145cec;
    }
  }
}

.swiperPage {
  width: 100%;
  height: $sh;
  position: relative;

  .auSwiperPrev,
  .auSwiperNext {
    position: absolute;
    top: 50%;
    z-index: 2;
    transform: translateY(-50%);
    color: #fff;
    opacity: 0.3;
    box-shadow: 0 0 10px -3px rgb(0 0 0 / 20%);
    -o-transition: all 0.5s;
    transition: all 0.5s;
    border-radius: 50%;
    overflow: hidden;
    img {
      display: block;
      width: 62px;
      height: 62px;
      cursor: pointer;
    }
  }
  .auSwiperPrev {
    transform: translateY(-50%) rotate(180deg);
    left: 17vw;
  }
  .auSwiperNext {
    right: 17vw;
  }
  &:hover {
    .auSwiperPrev,
    .auSwiperNext {
      opacity: 1;
      display: inline-block;
    }
    .auSwiperPrev {
      left: 15vw;
    }
    .auSwiperNext {
      right: 15vw;
    }
  }
}
.swiper-container {
  height: $sh+60px;
  //   width: $sw;
  overflow: hidden;
  position: relative;
  margin: auto;
  .swiper-wrapper {
    // background: rgba(42, 91, 165, 0.5);
  }
}
</style>


<style lang="scss">
.swiper-pagination-bullet {
  margin: 0 4px;
  width: 28px;
  height: 3px;
  background: #f7f8fc;
  opacity: 0.4;
  border-radius: 0;
}
.swiper-pagination-bullet-active {
  opacity: 1;
}
</style>