<template>
  <div class="page animate__fadeInUp">{{title}}</div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props:{
    title:{
        type:String,
        default:"标题"
    }
  },
};
</script>

<style lang="scss" scoped>
.page {
  font-size: 32px;
  font-weight: 500;
  color: #333333;
  margin: 0 auto 22px;
  display: block;
  line-height: 32px;
  text-align: center;
  padding: 45px 0 0;
  &::before,
  &::after {
    width: 137px;
    height: 1px;
    content: "";
    display: inline-block;
    vertical-align: middle;
    margin: 0 34px;
    background-image: linear-gradient(to left,var(--au-color),#fff);
  }
  &::after{
    background-image: linear-gradient(to right,var(--au-color),#fff);
  }
}
</style>