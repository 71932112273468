import { createRouter, createWebHistory, RouteRecordRaw,createWebHashHistory } from 'vue-router'
import Home from '../views/web/Home.vue'

//首页路由
const webRoutes:Array<RouteRecordRaw> =[
  {
    path:"",
    name:"Home",
    meta:{
      title:"土豆查 - 首页"
    },
    component:Home,
  },
  {
    path:"/extension",
    name:"Extension",
    meta:{
      title:"土豆查插件下载",
    },
    component:()=>import('../views/web/Extension.vue'),
  },
  {
    path:"/login",
    name:"Login",
    meta:{
      title:"登录",
    },
    component:()=>import('../views/web/Login.vue'),
  },
  {
    path:"/register",
    name:"Register",
    meta:{
      title:"注册",
    },
    component:()=>import('../views/web/Register.vue'),
  },
  {
    path:"/complain",
    name:"Complain",
    meta:{
      title:"意见反馈",
    },
    component:()=>import('../views/web/Complain.vue'),
  },
  {
    path:"/about",
    name:"About",
    meta:{
      title:"关于我们",
      hideFooter:true,
    },
    component:()=>import('../views/web/About.vue'),
  },
];

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'WebLayout',
    component: ()=>import('../views/web/layout/index.vue'),
    children:webRoutes
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('../views/admin/layout/index.vue')
  }
]

const router = createRouter({
  // history: createWebHashHistory(process.env.BASE_URL),
  history: createWebHistory(),
  routes
})


//设置title
router.beforeEach((to,from,next)=>{
  if(to.meta &&to.meta.title){
    document.title=(to.meta.title as string);
  }
  next();
})

export default router
